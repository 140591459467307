.p-checkbox-label {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #313131;
}



.create-user {

    padding: 20px;
    margin: 4%;
    background-color: #ffffff;


    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;


    .reg-title {
        font: normal normal normal 22px/33px Poppins;
        font-weight: 600;
        letter-spacing: 0.14px;
        color: var(--primary-color);
        margin: 10px;
    }

    .anot-title {
        font: normal normal normal 16px Poppins;
        font-weight: 600;
        letter-spacing: 0.14px;
        color: var(--primary-color);
        margin: 10px 10px 10px 0px;
    }

    .create-form {}

    .add-grade-btn {
        margin-top: 28px;
    }

    .delete-grade-btn {
        margin-top: 28px;
    }

    .no-grades {
        font: normal normal normal 12px/18px Poppins;
        letter-spacing: 0px;
        color: #737373;
    }
}



.user-info-d {
    width: 30%;
    .user-info {

     


    }



}


.password-copy {

    font-size: 18px;
    font-weight: 500;
}

.uploaded-photo {
    width: 160px;
    height: 160px;
}